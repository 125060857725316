import { React, useState, useEffect } from 'react';
import { Typography, Card, Form, Input, Row, Col, DatePicker, TimePicker, Grid, Select, InputNumber } from 'antd';
import dayjs from "dayjs";
import { CaretRightOutlined, SaveOutlined, LeftOutlined, RightOutlined, EyeFilled, CopyFilled, PlusOutlined, SearchOutlined, CloseOutlined, DeleteOutlined, CaretDownOutlined, EyeOutlined, EyeInvisibleOutlined, CheckOutlined, LinkOutlined, } from "@ant-design/icons";
import "../EndUserStyles.scss";
const { useBreakpoint } = Grid;
const { Option } = Select;

const EventInfo = ({ venueInfo, eventType, setSelectedEvent, setEventStartTime, setEventEndTime, setEventGuestCount, setEventDate }) => {
  const screens = useBreakpoint();
  const [month, setMonth] = useState(dayjs().format("YYYY-MM"));
  const [days, setDays] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]); // State for blocked dates
  const [eventAvailability, setEventAvailability] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);

  const visibleDays = 5;

  const endIndex = startIndex + visibleDays;

  const [dateDuration, setDateDuration] = useState({
    duration_minutes: "",
    event_date: "",
  });

  const { Title, Text } = Typography;
  const { MonthPicker } = DatePicker;
  useEffect(() => {
    const selectedMonth = dayjs(month);
    const daysInMonth = selectedMonth.daysInMonth();
    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = selectedMonth.date(day);
      const dayObject = {
        day: date.format("DD"),
        day_name: date.format("ddd"),
        full_date: date.format("YYYY-MM-DD"),
      };
      daysArray.push(dayObject);
    }
    setDays(daysArray);
  }, [month]);
  useEffect(() => {
    if (venueInfo && venueInfo?.web_calendar_view && venueInfo?.web_calendar_view.days) {
      setBlockedDates(venueInfo?.web_calendar_view.days);
    }
  }, [venueInfo]);


  const checkAvailability = (date) => {
    const currentDate = dayjs();
    const dateToCheckObj = dayjs(date);
    const isPassed = dateToCheckObj.isBefore(currentDate, "day");
    return Boolean(isPassed);
  };

  function disabledPastMonths(current) {
    const currentDate = new Date();
    return current && current.isBefore(currentDate, "month");
  }
  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - visibleDays);
    }
  };

  const handleNext = () => {
    if (endIndex < days.length) {
      setStartIndex((prev) => prev + visibleDays);
    }
  };
  const checkStartTime = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Start time is required"));
    }
    const formattedTime = value ? value.format("HH:mm") : "";
    setEventStartTime(formattedTime);
    return Promise.resolve();
  };

  const checkEndTime = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("End time is required"));
    }
    const formattedTime = value ? value.format("HH:mm") : "";
    setEventEndTime(formattedTime);
    return Promise.resolve();
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    setDateDuration((prev) => ({
      ...prev,
      event_date: formattedDate,
    }));
    setEventDate(formattedDate);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and limit the value to 1000
    if (/^\d*$/.test(value) && Number(value) <= 1000) {
      setEventGuestCount(value);
    }
  };
  return (
    <Card className="booking-card">
      <Title level={4}>Event information</Title>
      <Row gutter={[16, 0]}>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name="event_type"
            label={<span>Hosting Event <span style={{ color: 'red' }}>*</span></span>}

            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Select event type"
              style={{ width: "100%" }}
              onChange={(value) => setSelectedEvent(value)}
            >
              {eventType?.map((item, key) => (
                <Option value={item.id} key={key}>
                  {item.name}
                </Option>
              ))}
            </Select>

          </Form.Item>
        </Col>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name="num_of_guests"
            label={<span>Guest Count<span style={{ color: 'red' }}>*</span></span>}
            rules={[
              {
                required: true,
                message: "Please enter a guests number!",
              },
              {
                validator: (_, value) => {
                  if (Number(value) < 0) {
                    return Promise.reject("Please enter a valid guests number!");
                  } else if (Number(value) > 1000) {
                    return Promise.reject("Max 1000 guests are allowed!");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input
              onChange={handleInputChange}
              size="large"
              placeholder="Number of guests"
              maxLength={4}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            style={{ width: "100%" }}
            name="start_time"
            label={<span>Start Time <span style={{ color: 'red' }}>*</span></span>}
            rules={[{ validator: checkStartTime }]}
          >
            <TimePicker
              size="large"
              showNow={false}
              style={{ width: "100%" }}
              format="hh:mm A"
            />
          </Form.Item>
        </Col>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            style={{ width: "100%" }}
            name="end_time"
            label={<span>End Time <span style={{ color: 'red' }}>*</span></span>}
            rules={[{ validator: checkEndTime }]}
          >
            <TimePicker
              size="large"
              showNow={false}
              style={{ width: "100%" }}
              format="hh:mm A"
            />
          </Form.Item>
        </Col>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name="event_date"
            label={<span>Event Date <span style={{ color: 'red' }}>*</span></span>}
            rules={[
              { required: true, message: "Event date is required!" },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: '100%' }}
              disabledDate={(current) =>
                blockedDates.includes(current.format('YYYY-MM-DD')) || current.isBefore(dayjs().startOf('day'))
              }
              onChange={handleDateChange}
            />

          </Form.Item>
        </Col>

        {/* <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name="event_date"
            label={<span>Event Date<span style={{ color: 'red' }}>*</span></span>}

            rules={[
              {
                required: true,
                message: "Event date is required!",
              },
            ]}
          >
            <DatePicker size="large" style={{ width: '100%' }} />
            <div className="data-time-card">
              <div className="inner-body">
                <div className="date-picker-header">
                  <MonthPicker
                    value={dayjs(month)}
                    // disabledDate={disabledPastMonths}
                    onChange={(date) => {
                      setMonth(dayjs(date).format("YYYY-MM"));
                      setDateDuration((prev) => ({ ...prev, event_date: "" }));
                    }}
                    format={"MMMM YYYY"}
                    bordered={false}
                    suffixIcon={<CaretDownOutlined />}
                  />
                </div>
                <div className="days-wrapper">
                  {days?.length
                    ? days.map((day, i) =>
                      !checkAvailability(day?.full_date) ? (
                        <div
                          key={i}
                          className={`day ${day.full_date === dateDuration?.event_date
                              ? "active"
                              : null
                            } ${eventAvailability?.disable?.includes(
                              day?.full_date
                            ) && "disabled"
                            }`}
                          onClick={() =>
                            setDateDuration((prev) => ({
                              ...prev,
                              event_date: day.full_date,
                            }))
                          }
                        >
                          <Text type="secondary">{day?.day_name}</Text>
                          <Text>{day.day}</Text>
                        </div>
                      ) : null
                    )
                    : null}
                </div>
              </div>
            </div> 
          </Form.Item>
        </Col> */}
      </Row>
    </Card>
  );
};

export default EventInfo;
