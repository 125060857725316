import React, { useState, useEffect } from "react";
import { Typography, Card, Divider, Checkbox, Button, Empty ,Grid} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dummyPhoto from "../Assets/photo.png";
import TermsModal from "./TermsModal";
import moment from "moment";
const { useBreakpoint } = Grid;

const TransactionDetail = ({
  dateDuration,
  paymentTerms,
  setPaymentTerms,
  paymentInfo,
  details,
  sebmitButton,
  selectedPackage,
  services,
  selectedRooms,
  selectedServices,
  eventGuestCount,
  pricingModel,
}) => {
  const screens = useBreakpoint();
  const { Title, Text } = Typography;
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [termModal, setTermModal] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isTermsNotOpenedModal, setIsTermsNotOpenedModal] = useState(false);
  const [isTermsOpened, setIsTermsOpened] = useState(false);

  const calculateTotal = (items, pricingType) => {
    return items.reduce((acc, item) => {
      const price = parseFloat(item?.price || 0);
      return acc + (pricingType === "PER_PERSON" && eventGuestCount > 0 ? price * eventGuestCount : price);
    }, 0);
  };

  useEffect(() => {
    const servicesTotal = calculateTotal(selectedServices, pricingModel.servicesPricingType);
    const roomsTotal = calculateTotal(selectedRooms, pricingModel.roomsPricingType);
    const packagesTotal = calculateTotal(selectedPackage, pricingModel.packagesPricingType);

    const total = servicesTotal + roomsTotal + packagesTotal;
    setTotalPrice(total);

    // Calculate subtotal: Total - Discount + Tax
    const calculatedSubtotal = total - discount + tax;
    setSubtotal(calculatedSubtotal);
  }, [selectedServices, selectedRooms, selectedPackage, eventGuestCount, pricingModel, discount, tax]);

  const handleDelete = (item) => {
    // Remove item from paymentTerms
    
    const newPaymentTerms = paymentTerms.filter((term) => term.payment_date !== item.payment_date);

    // if paymentTerms are 2, get percentage of each payment from payment.percentage

    if (newPaymentTerms.length === 2) {
      newPaymentTerms.forEach((payment, index) => {
        newPaymentTerms[index] = {
          payment_date: payment.payment_date,
          percentage: 50,
        };
      });
    } else if (newPaymentTerms.length === 1) {
      newPaymentTerms[0] = {
        payment_date: newPaymentTerms[0].payment_date,
        percentage: 100,
      };
    }

    setPaymentTerms(newPaymentTerms);
  };

  const renderItems = (items, pricingType, sectionTitle) => (
    <>
      {items?.length > 0 && (
        <>
          <Title level={5} style={{ marginTop: "5px" }}>{sectionTitle}</Title>
          {items.map((item) => (
            <div
              key={item.id}
              className="cart-item"
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img
                  src={item?.image || dummyPhoto}
                  style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px" }}
                  alt={item?.name}
                />
                <Typography className="cart-item-title">{item?.name}</Typography>
              </div>
              <Typography style={{ marginTop: "6px", marginRight: "16px" }}>
                {pricingType === "PER_PERSON" && eventGuestCount > 0
                  ? (item.price * eventGuestCount)
                  : parseFloat(item?.price)}$
              </Typography>
            </div>
          ))}
        </>
      )}
    </>
  );

  return (
    <>
      <Card className="cart-details-card">
        <Title type="secondary" level={4}>Transaction Detail</Title>
        {totalPrice > 0 ? (
          <>
            {renderItems(selectedRooms, pricingModel?.roomsPricingType, "Rooms")}
            {renderItems(selectedPackage, pricingModel?.packagesPricingType, "Packages")}
            {renderItems(selectedServices, pricingModel?.servicesPricingType, "Services")}

            <Divider />

            <div className="cart-total">
              <Title className="total-title" level={5}>Total</Title>
              <Text style={{  marginTop: screens.xs ? "0px" : "6px", marginRight: "16px", color: "#000000", fontWeight: "700" }}>{totalPrice ? totalPrice : 0}$</Text>
            </div>
            <Divider />
            <div className="cart-total">
              <Title className="total-title" level={5}>Discount</Title>
              <Text style={{marginTop: screens.xs ? "0px" : "6px", marginRight: "16px", color: "#000000" }}>{discount ? discount : 0}$</Text>
            </div>
            <div className="cart-total">
              <Title className="total-title" level={5}>TAX</Title>
              <Text style={{marginTop: screens.xs ? "0px" : "6px",  marginRight: "16px", color: "#000000" }}>{tax ? tax : 0}$</Text>
            </div>
            {/* <Divider /> */}
            <div className="cart-total">
              <Title className="total-title" level={5}>Sub Total</Title>
              <Text style={{marginTop: screens.xs ? "0px" : "6px", marginRight: "16px", color: "#000000", fontWeight: "700" }}>{subtotal ? subtotal : 0}$</Text>
            </div>
            <Divider />
            {/* {paymentInfo?.is_partial_allowed && ( */}
            {paymentTerms.length > 0 && (
              <>
                <div className="detail-list">
                  <Title type="secondary" level={4}>Installment Payments</Title>
                  <div className="list-wrapper">
                    {paymentTerms?.map((item, index) => {
                      const installmentAmount = (totalPrice * parseFloat(item.percentage)) / 100;
                      const dueDate = index === 0
                        ? moment().format("MMM DD, YYYY")
                        : moment(dateDuration?.event_date, "YYYY-MM-DD")
                          .subtract(item?.payment_day || 0, "days")
                          .format("MMM DD, YYYY");

                      return (
                        <div key={index} className="list">
                          {/* Due on {dueDate} <b>${installmentAmount}</b> */}
                          Due on {item.payment_date} <b>${installmentAmount}</b>
                          <Button className="delete-list" onClick={() => handleDelete(item)} icon={<DeleteOutlined style={{ color: '#EB3D4D' }} />} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Please Select Rooms, Packages, or Services"
          />
        )}
        <div className="term-check">
          <div>
            <Checkbox
              checked={isTermsAccepted}
              onChange={(val) => {
                if (isTermsOpened) {
                  setIsTermsAccepted(val.target.checked);
                  setIsTermsNotOpenedModal(false);
                } else {
                  setIsTermsNotOpenedModal(true);
                }
              }}
            />
          </div>

          <div>
            I Agree to the{" "}
            <a
              onClick={() => {
                setTermModal(true);
                setIsTermsOpened(true);
              }}
            >
              Term & Conditions and Privacy Policy
            </a>
          </div>
        </div>

        {isTermsNotOpenedModal && (
          <p style={{ color: "red" }}>* Please review the Terms and conditions *</p>
        )}

        {sebmitButton(isTermsAccepted)}
      </Card>

      <TermsModal
        open={termModal}
        setChecked={setIsTermsAccepted}
        handelClose={() => setTermModal(false)}
        content={details?.terms}
      />
    </>
  );
};

export default TransactionDetail;
