import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Input, Select, Radio } from 'antd';
import Loader from '../../../common/Loader';
import '../EndUserStyles.scss';
import dummyPhoto from '../Assets/photo.png';

const { useBreakpoint } = Grid;

const Rooms = ({ room, setRoom, rooms, validateFields ,pricingModel}) => {
  const { Title } = Typography;
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const screens = useBreakpoint();


  
  // Mapping the rooms into data source format for the table
  const dataSource = rooms?.map((item, index) => ({
    id: item?.id,
    key: index,
    name: item?.name,
    description: item?.description || "",
    image: item?.images?.length ? item?.images[0]?.image : dummyPhoto,
    price:  pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
  }));

  // Columns for Ant Design Table
  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 40,
      render: (text) => (
        <img
          src={text}
          alt="Room"
          style={{ width: '50px', height: '50px', borderRadius: '5px', marginTop: '3px' }}
        />
      ),
    },
    {
      title: '',
      width: '50%',
      key: 'service',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
          <h4 style={{ margin: 0 }}>{record.name}</h4>
          <p style={{ margin: 0 }}>{record.description}</p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <h4 style={{ margin: 0 }}>${record.price || 0}</h4>,
    },
    {
      title: 'Select',
      key: 'select',
      width: 40,
      render: (text, record) => (
        <Checkbox
          checked={room.some((selectedRoom) => selectedRoom.id === record.id)} 
          onChange={(e) => {
            if (e.target.checked) {
              setRoom((prevSelected) => [...prevSelected, record]);
            } else {
              setRoom((prevSelected) =>
                prevSelected.filter((selectedRoom) => selectedRoom.id !== record.id)
              );
            }
          }}
        />
      ),
    },
  ];

    return (
        <>
        {/* <Card
          className="booking-card"
          style={{
            border: room === "" && validateFields === true ? "1px solid red" : null,
          }}
        >
          <Title level={4}>Select Available Rooms</Title>
          <div className="cards-wrapper">
            <Radio.Group
              style={{ display: "flex", gap: 24 }}
              onChange={(e) => {
                const selected = venues?.find(item => item.id === e.target.value);
                setRoom(e.target.value);
              }}
              value={room}
            >
              {rooms?.length ? (
                rooms.map((item, i) => (
                  <Card
                    className={item.id === room && "seletced"}
                    key={i}
                    style={{ minWidth: 310, maxWidth: 310 }}
                  >
                    <div>
                      <img
                        src={
                          item?.images?.length
                            ? item?.images[0]?.image
                            : placeholderImage
                        }
                        class="img-fluid venue-image"
                        alt="room"
                      />
                    </div>
                    <p style={{ fontWeight: 700 }}>{item?.name}</p>
                    <div className="packages-info">
                      <p className="packages-desc">Room Type</p>
                      <p
                        className="packages-desc value"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gat: 4,
                          maxWidth: "60%",
                        }}
                      >
                        {item?.ceremony_types?.length
                          ? item?.ceremony_types.map((item, i) => (
                              <p key={i}>{item}</p>
                            ))
                          : "-"}
                      </p>
                    </div>
                    <div className="packages-info">
                                    <p className="packages-desc">Price</p>
                                    <p className="packages-desc value">${item?.base_price}</p>
                                </div>
                    <div className="packages-info" style={{ marginBottom: 24 }}>
                      <p className="packages-desc">Maximum Capacity</p>
                      <p className="packages-desc value">{item?.max_guests}</p>
                    </div>
                    <div className="border-bottom" />
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        paddingBlock: 6,
                      }}
                    >
                      <PackagesIcon /> <Text style={{ fontSize: 12 }}>0 Packages</Text>
                      <Radio
                        style={{ marginTop: "10px", marginLeft: "auto" }}
                        size="large"
                        value={item.id}
                      />
                    </div>
                  </Card>
                ))
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Rooms"
                />
              )}
            </Radio.Group>
          </div>
        </Card> */}


        {/* <div> */}
        <Card style={{ marginTop: screens.xs ? "10px" : "20px" }}>
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Title level={4}>Rooms</Title>
                </Space>
                <div
                    className="events-details"
                    style={{ marginTop: screens.xs ? "10px" : "0" }}
                >
                    <p
                        className="event-head"
                        style={{ color: "#667085", pointerEvents: "none" }}
                    >
                        Select Rooms to add
                    </p>
                </div>

                <div className="item-details" style={{ height: "140px" }}>
                    <Card>
                        {loading && !rooms?.length ? (
                            <Loader minHeight={400} />
                        ) : rooms?.length ? (
                            <Table
                                scroll={{ x: 600 }}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                showHeader={false}
                            />
                        ) : !loading ? (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No Rooms"
                            />
                        ) : null}
                    </Card>
                </div>

            </Card>

        {/* </div> */}
        </>
        
    );
};

export default Rooms;