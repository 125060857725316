import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Calendar, Select, Button, Tooltip, DatePicker, Grid } from "antd";
import { CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CloseOutlined, InfoOutlined, CheckOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import { getEvents } from "../../../services/events";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;
const EventCalender = ({ location, setBlockedDates, blockedDates }) => {
    const { Title } = Typography;
    const screens = useBreakpoint();
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [blockWeekday, setBlockWeekday] = useState(null);
    const [blockDateRange, setBlockDateRange] = useState(null);

    const fetchEvents = async () => {
        setLoading(true);
        try {
            const res = await getEvents({ location: location?.id, page_size: 100 });
            setEvents(res?.data?.results);
        } catch (error) {
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (location?.id) {
            fetchEvents();
        }
    }, [location]);

    const toggleBlockedDate = (date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        setBlockedDates(prevBlockedDates => {
            if (prevBlockedDates.includes(formattedDate)) {
                return prevBlockedDates.filter(d => d !== formattedDate);
            } else {
                return [...prevBlockedDates, formattedDate];
            }
        });
    };

    const blockCustomDates = () => {
        if (blockDateRange) {
            const [start, end] = blockDateRange;
            const newBlockedDates = [];

            if (blockWeekday === null) {
                // Block all dates in the selected range
                for (let date = start.clone(); date.isBefore(end) || date.isSame(end, 'day'); date = date.add(1, 'day')) {
                    newBlockedDates.push(date.format("YYYY-MM-DD"));
                }
            } else {
                // Block only the specific weekday in the selected range
                for (let date = start.clone(); date.isBefore(end) || date.isSame(end, 'day'); date = date.add(1, 'day')) {
                    if (date.day() === blockWeekday) {
                        newBlockedDates.push(date.format("YYYY-MM-DD"));
                    }
                }
            }

            setBlockedDates([...blockedDates, ...newBlockedDates]);
            setBlockWeekday(null);
            setBlockDateRange(null);
            toast.success("Custom dates blocked successfully!");
        } else {
            toast.error("Please select a date range!");
        }
    };

    // console.log(blockedDates)

    const resetBlockedDates = () => {
        setBlockedDates([]);
        toast.success("All blocked dates have been reset!");
    };

    const customHeader = ({ value, onChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        let current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
        }
        for (let i = start; i < end; i++) {
            monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                </Select.Option>,
            );
        }
        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }

        return (
            <div style={{ paddingBlock: 8, paddingInline: 16 }}>
                <div className="calendar-header">
                    <Button
                        type="text"
                        className="month-switch"
                        icon={<CaretLeftOutlined />}
                        size={"large"}
                        onClick={() => {
                            const now = value.clone().month(month - 1);
                            onChange(now);
                        }}
                        style={{ marginRight: "auto" }}
                    />
                    <Select
                        size="large"
                        bordered={false}
                        popupMatchSelectWidth={false}
                        value={month}
                        suffixIcon={<CaretDownOutlined />}
                        style={{ minWidth: 80 }}
                        onChange={(newMonth) => {
                            const now = value.clone().month(newMonth);
                            onChange(now);
                        }}
                    >
                        {monthOptions}
                    </Select>
                    <Select
                        size="large"
                        style={{ minWidth: 90 }}
                        bordered={false}
                        popupMatchSelectWidth={false}
                        className="my-year-select"
                        suffixIcon={<CaretDownOutlined />}
                        value={year}
                        onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                        }}
                    >
                        {options}
                    </Select>
                    <Button
                        type="text"
                        className="month-switch"
                        icon={<CaretRightOutlined />}
                        size={"large"}
                        onClick={() => {
                            const now = value.clone().month(month + 1);
                            onChange(now);
                        }}
                        style={{ marginLeft: "auto" }}
                    />
                </div>
            </div>
        );
    };

    const customCellRender = (date) => {
        const dateValue = date.date();
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        const isBlocked = blockedDates.includes(formattedDate);
        const eventDates = events.length ? events.map((item) => item.event_date) : [];
        const checkEvent = eventDates.length ? eventDates.includes(formattedDate) : false;

        return (
            <Tooltip title={"Click on any date to block or unblock it"}>
                <div
                    className="ant-picker-cell-inner ant-picker-calendar-date"
                    onClick={() => toggleBlockedDate(date)}
                    style={{ cursor: 'pointer', position: 'relative' }}
                >
                    <div className={`ant-picker-calendar-date-value ${checkEvent ? "have-events" : ""}`}>
                        {dateValue}
                    </div>
                    {isBlocked && (
                        <CloseOutlined style={{ color: 'red', position: 'absolute', top: 5, right: 5, fontSize: "20px" }} />
                    )}
                </div>
            </Tooltip>
        );
    };

    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day');
    };

    return (
        <div>
            <Card style={{ marginTop: "20px", }}>
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Title level={4}>Event Calendar
                        &nbsp;<Tooltip placement="top" title={"Click on any date to block it"}>
                            <InfoOutlined
                                className='add-input-field'
                                style={{ marginBottom: '10px', cursor: 'pointer' }}
                            />
                        </Tooltip>
                    </Title>

                    {
                        screens.xs ? (
                            <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                            </div>
                        ) : null
                    }

                </Space>
                <Space direction={screens.xs ? "vertical" : "horizontal"} size={screens.xs ? 12 : 16}>
                    <Space>
                        <Select
                            style={{ width: 150 }}
                            onChange={(value) => setBlockWeekday(value)}
                            defaultValue={null}
                        >
                            <Select.Option value={null}>Select Weekday</Select.Option>
                            <Select.Option value={0}>Sunday</Select.Option>
                            <Select.Option value={1}>Monday</Select.Option>
                            <Select.Option value={2}>Tuesday</Select.Option>
                            <Select.Option value={3}>Wednesday</Select.Option>
                            <Select.Option value={4}>Thursday</Select.Option>
                            <Select.Option value={5}>Friday</Select.Option>
                            <Select.Option value={6}>Saturday</Select.Option>
                        </Select>

                        <DatePicker.RangePicker
                            onChange={(dates) => setBlockDateRange(dates)}
                            disabledDate={(current) => {
                                return current && current < dayjs().startOf('day');
                            }}
                        />

                    </Space>
                    <Space>
                        <Button type="primary" onClick={blockCustomDates}>
                            Block Custom Dates
                        </Button>

                        <Button danger onClick={resetBlockedDates}>
                            Reset Blocked
                        </Button>
                    </Space>
                </Space>
                <div className="calendar-content-wrapper">
                    <div className="calendar-wrapper" style={{ maxWidth: "100%" }}>
                        <Calendar
                            fullscreen={false}
                            headerRender={customHeader}
                            onChange={(value) => setSelectedDate(value.format("YYYY-MM-DD"))}
                            dateFullCellRender={customCellRender}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default EventCalender;