import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

// UI's
import { Typography, Space, Button, Row, Col, Grid } from "antd";
import { CaretRightOutlined, SaveOutlined, EyeOutlined, LinkOutlined, FormOutlined } from "@ant-design/icons";
import { generateCode } from "../../services/integrations";
import usePopup from "../../common/MessageModal/usePopup";
// CSS
import "./integrationStyles.scss";

import Contracts from "./comps/Contracts";
import EventCalender from "./comps/EventCalender";
import ImageUploader from "./comps/ImageUploader";
import Payments from "./comps/Payments";
import PricingModel from "./comps/PricingModel";
import RoomDetails from "./comps/RoomDetails";
import Services from "./comps/Services";
import ShowCode from "./comps/ShowCode";
import StartGuide from "./comps/StartGuide";
import UserPackages from "./comps/UserPackages";
import VenueDetails from "./comps/VenueDetails";
import WelcomeMessageModal from "./comps/WelcomeMessageModal";
import CheckStripeInfoModal from "./comps/CheckStripeInfoModal";
import VenueModal from "./comps/VenueModal";

const { useBreakpoint } = Grid;
const Integrations = () => {
  const screens = useBreakpoint();
  const popup = usePopup();
  const { Title } = Typography;
  const selectedVenue = useSelector((state) => state?.venues?.selectedVenue);

  const [screenIndex, setScreenIndex] = useState(0)
  const [errors, setErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [generatedCode, setGeneratedCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const [terms, setTerms] = useState({ title: "", terms_html: "" });
  const [venue, setVenueData] = useState([]);
  const [venueDetails, setVenueDetails] = useState({});
  const [roomDetails, setRoomsDetails] = useState({})
  const [selectedPackages, setSelectedPackages] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [enableServices, setEnableServices] = useState(true)
  const [enablePackages, setEnablePackages] = useState(true)
  const [pricingModel, setPricingModel] = useState({});
  const [blockedDates, setBlockedDates] = useState([]);
  const [payments, setPayments] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [selectedVenueImage, setSelectedVenueImage] = useState(null);
  const [stripeAccountStatus, setStripeAccountStatus] = useState(false)

  const [welcomeModal, setWelcomeModal] = useState(true);
  const [checkStripe, setCheckStripe] = useState(false);
  const [venueMissingModal, setVenueMissingModal] = useState(false);

  const venueName = selectedVenue?.name === undefined ? selectedVenue?.label : selectedVenue?.name;

  // adding NEW API

  const closeWelcomeModal = () => {
    setWelcomeModal((prev) => !prev)
    venue?.length === 0 ? setVenueMissingModal(true) : setCheckStripe(true);
  }

  const closeVenueModal = () => {
    setVenueMissingModal((prev) => !prev)
    setCheckStripe(true)
  }

  const closeStripeModal = () => {
    setCheckStripe((prev) => !prev)
  }

  useEffect(() => {
    if (selectedVenue) {
      console.log("Selected Venue ID:", selectedVenue);
    }
  }, [selectedVenue]);

  const validateFields = () => {
    const errors = {};
    if (venueDetails?.phone?.length <= 13 || !venueDetails?.email) {
      errors.venue = "Please fill in the required fields";
    }
    if (!roomDetails?.guestCount_0 || !roomDetails?.Price_0) {
      errors.roomDetails = "Please fill in the required fields";
    }
    // if (!selectedPackages?.length) {
    //   errors.packages = "Please select at least one package.";
    // }
    // if (!selectedServices?.length) {
    //   errors.services = "Please select at least one service.";
    // }
    if (pricingModel?.packages === undefined || pricingModel?.services === undefined || pricingModel?.rooms === undefined) {
      errors.pricingModel = "Please select all the pricing model details.";
    }
    if (selectedVenueImage === null) {
      errors.images = "Please Select any venue Image"
    }
    if (profileImage === null) {
      errors.images = "Please Upload Company Logo"
    }
    if (!terms?.title) {
      errors.terms = { ...errors.terms, title: "This field is required" };
    }
    if (!terms?.terms_html) {
      errors.terms = { ...errors.terms, terms_html: "This field is required" };
    }
    if (stripeAccountStatus === false) {
      errors.stripe = "Please link stripe"
      // setCheckStripe(true)
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const generateIframeCode = async (type) => {
    // if (validateFields()) return;
    if (type !== "Draft" && validateFields()) return;
    setLoading(true);
    console.log(type)
    const generated_url = window.location.origin + "/booking/" + venueName + "-" + selectedVenue?.id;
    try {
      const payload = {
        reference_name: "PlanSpace website integration",
        packages: enablePackages == true ? selectedPackages.join(",") : "",
        addons: enableServices == true ? selectedServices.join(",") : "",
        terms: JSON.stringify(terms),
        location: selectedVenue?.id,
        status: type === "Draft" ? "draft" : "activated",
        generated_url: generated_url?.toLowerCase().replace(/ /g, "-"),
        packages_pm: pricingModel?.packages,
        rooms_pm: pricingModel?.rooms,
        addons_pm: pricingModel?.services,
        email: venueDetails?.email,
        phone: venueDetails?.phone,
        about_us_text: venueDetails?.about_us_text,
        banner: selectedVenueImage?.image ? selectedVenueImage?.image : "",
        company_logo: profileImage,
        blocked_days: blockedDates,
        rooms: venue?.rooms?.length === 0 ? [] : roomDetails,
        payment_terms: payments,
      };

      let formData = new FormData();
      for (let key in payload) {
        if (key === "payment_terms") {
          payments.forEach((item, index) => {
            formData.append(`payment_terms[${index}][payment_date]`, item.payment_date ? item.payment_date : "");
            formData.append(`payment_terms[${index}][is_partials_allowed]`, item.is_partials_allowed);
            formData.append(`payment_terms[${index}][percentage]`, item.percentage);
          })
        } else {
          formData.append(key, payload[key]);
        }
      }

      console.log(payload)
      const res = await generateCode(formData);
      setGeneratedCode(res?.data?.data);

      if (type === "preview") {
        window.open(res?.data?.data?.generated_url, "_blank");
      } else {
        setShowModal(type);
      }
      if (type !== "Draft") {
        popup.success(res?.data?.message);
      }
      // setTimeout(() => {
      //   window.location.reload()
      // }, 3500);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGeneratedCode(null);
  }, [venue, selectedPackages, payments, terms]);

  const navigateNextPageInMobile = () => {
    setScreenIndex(screenIndex + 1)
  }

  const navigatePreviousPageInMobile = () => {
    setScreenIndex(screenIndex - 1)
  }

  return (
    <div>
      <div className="main-integration-styles">
        <div className="booking-head">
          <Space>
            <Title className="main-head">Venue Booking Tool</Title>
          </Space>
          <div className="text-alignment">
            <div className="events-details">
              <p className="event-head" style={{ color: "#667085" }}>
                Integrations
              </p>
              <p className="event-head" style={{ color: "#667085" }}>
                <CaretRightOutlined /> Booking Tool Setups
              </p>
              <p className="event-head" style={{ color: "#0B98D2" }}>
                <CaretRightOutlined /> {venueName}
              </p>
            </div>
            <div className="buttons-integration">
              <Button
                style={{ marginRight: "5px" }}
                className="cancel-button"
                icon={<FormOutlined />}
                onClick={() => generateIframeCode("Draft")}
                loading={loading}
              >
                {/* Save as draft */}
                {screens.xs ? "Draft" : "Save as draft"}
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                className="save-button"
                icon={<SaveOutlined />}
                onClick={() => {
                  if (generatedCode) {
                    setShowModal("Integrations Code");
                  } else {
                    generateIframeCode("Integrations Code");
                  }
                }}
                loading={loading}
              >
                Save
                {/* {screens.xs ? "" : "Save"} */}
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                className="save-button"
                icon={<EyeOutlined />}
                loading={loading}
                onClick={() => {
                  if (generatedCode) {
                    window.open(generatedCode?.generated_url, "_blank");
                  } else {
                    generateIframeCode("preview");
                  }
                }}
              >
                Preview
                {/* {screens.xs ? "" : "Preview"} */}
              </Button>
              <Button
                type="primary"
                className="send-button"
                icon={<LinkOutlined />}
                onClick={() => {
                  if (generatedCode) {
                    setShowModal("Send by Email");
                  } else {
                    generateIframeCode("Send by Email");
                  }
                }}
                loading={loading}
              >
                {/* Send by Email */}
                {screens.xs ? "Email" : "Send by Email"}
              </Button>
            </div>
          </div>
        </div>
        <div className="form-integration">
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>

              {
                screens.xs ? (
                  <>
                    {screenIndex === 0 && (
                      <VenueDetails
                        error={errors?.venue}
                        setErrors={setErrors}
                        setVenueDetails={setVenueDetails}
                        venueDetails={venueDetails}
                        venue={venue}
                        setVenue={setVenueData}
                        selectedVenue={selectedVenue}
                        venueMissingModal={venueMissingModal}
                      />
                    )}

                    {screenIndex === 0 && (
                      <PricingModel
                        setPricingModel={setPricingModel}
                      />
                    )}

                    {screenIndex === 1 && (
                      <RoomDetails
                        error={errors?.roomDetails}
                        setErrors={setErrors}
                        setRoomsDetails={setRoomsDetails}
                        selectedVenue={selectedVenue}
                        pricingModel={pricingModel?.rooms}
                        roomDetails={roomDetails}
                      />
                    )}

                    {screenIndex === 1 && (
                      <Services
                        error={errors?.services}
                        setErrors={setErrors}
                        setSelectedServices={setSelectedServices}
                        selectedServices={selectedServices}
                        setEnableServices={setEnableServices}
                        enableServices={enableServices}
                        pricingModel={pricingModel?.services}
                      />
                    )}

                    {screenIndex === 2 && (
                      <UserPackages
                        error={errors?.packages}
                        setErrors={setErrors}
                        venue={selectedVenue}
                        setSelectedPackages={setSelectedPackages}
                        selectedPackages={selectedPackages}
                        setEnablePackages={setEnablePackages}
                        enablePackages={enablePackages}
                        pricingModel={pricingModel?.packages}
                      />
                    )}

                    {screenIndex === 2 && (
                      <EventCalender
                        location={selectedVenue}
                        blockedDates={blockedDates}
                        setBlockedDates={setBlockedDates}
                      />
                    )}

                    {screenIndex === 3 && (
                      <Payments createdPayments={setPayments} />
                    )}

                    {screenIndex === 3 && (
                      <ImageUploader
                        venue={venue}
                        selectedVenueImage={selectedVenueImage}
                        profileImage={profileImage}
                        setProfileImage={setProfileImage}
                        setSelectedVenueImage={setSelectedVenueImage}
                        setErrors={setErrors}
                        error={errors?.images}
                      />
                    )}

                    {screenIndex === 4 && (
                      <Contracts
                        error={errors?.terms}
                        setErrors={setErrors}
                        terms={terms}
                        setTerms={setTerms}
                      />
                    )}

                  </>
                ) : (
                  <>

                    <VenueDetails
                      error={errors?.venue}
                      setErrors={setErrors}
                      setVenueDetails={setVenueDetails}
                      venueDetails={venueDetails}
                      venue={venue}
                      setVenue={setVenueData}
                      selectedVenue={selectedVenue}
                      venueMissingModal={venueMissingModal}
                    />

                    <PricingModel
                      setPricingModel={setPricingModel}
                    />

                    <RoomDetails
                      error={errors?.roomDetails}
                      setErrors={setErrors}
                      setRoomsDetails={setRoomsDetails}
                      selectedVenue={selectedVenue}
                      pricingModel={pricingModel?.rooms}
                      roomDetails={roomDetails}
                    />

                    <Services
                      error={errors?.services}
                      setErrors={setErrors}
                      setSelectedServices={setSelectedServices}
                      selectedServices={selectedServices}
                      setEnableServices={setEnableServices}
                      enableServices={enableServices}
                      pricingModel={pricingModel?.services}
                    />

                    <UserPackages
                      error={errors?.packages}
                      setErrors={setErrors}
                      venue={selectedVenue}
                      setSelectedPackages={setSelectedPackages}
                      selectedPackages={selectedPackages}
                      setEnablePackages={setEnablePackages}
                      enablePackages={enablePackages}
                      pricingModel={pricingModel?.packages}
                    />

                    <EventCalender
                      location={selectedVenue}
                      blockedDates={blockedDates}
                      setBlockedDates={setBlockedDates}
                    />

                    <Payments createdPayments={setPayments} />

                    <ImageUploader
                      venue={venue}
                      selectedVenueImage={selectedVenueImage}
                      profileImage={profileImage}
                      setProfileImage={setProfileImage}
                      setSelectedVenueImage={setSelectedVenueImage}
                      setErrors={setErrors}
                      error={errors?.images}
                    />

                    <Contracts
                      error={errors?.terms}
                      setErrors={setErrors}
                      terms={terms}
                      setTerms={setTerms}
                    />

                  </>

                )
              }

            </Col>

            {
              screens.xs ? null : (
                <>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <StartGuide
                      venueDetails={venueDetails}
                      terms={terms}
                      servicesError={errors?.services}
                      packagesError={errors?.packages}
                      pricingModel={pricingModel}
                      roomDetails={roomDetails}
                      profileImage={profileImage}
                      selectedVenueImage={selectedVenueImage}
                      stripeAccountStatus={stripeAccountStatus}
                      enableServices={enableServices}
                      enablePackages={enablePackages}
                      selectedServices={selectedServices}
                      selectedPackages={selectedPackages}
                    />
                  </Col>
                </>
              )
            }

            {
              screens.xs ?
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 10px 10px 0px" }}>
                    {
                      screenIndex !== 0 ?
                        <Button className="button-terms" size="large" onClick={navigatePreviousPageInMobile}>Go Back</Button>
                        : <div />
                    }
                    {
                      screenIndex < 4 ?
                        <Button className="button-terms" size="large" onClick={navigateNextPageInMobile}>Move To Next</Button> : null
                    }
                  </div>
                </Col>
                : null
            }

          </Row>
        </div>

        {welcomeModal && <WelcomeMessageModal welcomeModal={welcomeModal} closeWelcomeModal={closeWelcomeModal} />}

        {checkStripe && <CheckStripeInfoModal checkStripe={checkStripe} setCheckStripe={closeStripeModal} stripeAccountStatus={stripeAccountStatus} setStripeAccountStatus={setStripeAccountStatus} />}

        {venueMissingModal && <VenueModal venueMissingModal={venueMissingModal} setVenueMissingModal={closeVenueModal} />}

        <ShowCode
          open={showModal}
          setOpen={setShowModal}
          handelClose={() => setShowModal(false)}
          generatedCode={generatedCode}
        />

      </div>
    </div>

  );
};

export default Integrations;
